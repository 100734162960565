/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";

import { AppContext } from "~context/AppContext";
import CartIcon from "~components/CartIcon";
import Footer from "~components/Footer";

class HeaderComponent extends Component {
  // Need both, because ssr returns // and browser returns /
  excludedPaths = [`/linktree`, `/linktree/`];

  hoverRotation = [-1, -0.5, 0.5, 1];

  headerCollapseHeight = 20;

  //

  toggleCart = () => {
    this.props.appContext.setCartActive(!this.props.appContext.cartActive);
  };

  toggleMenu = () => {
    this.props.appContext.setMenuActive(!this.props.appContext.menuActive);
  };

  //

  render() {
    const { appContext, location } = this.props;
    if (
      location &&
      location.pathname &&
      this.excludedPaths.includes(location.pathname)
    ) {
      return <></>;
    }

    return (
      <header
        ref={this.menuRef}
        className="h-full w-full relative bg-sun-yellow"
      >
        <div
          className={`header w-full fixed z-40 bg-sun-yellow ${
            appContext.cartActive ? `cart-active` : ``
          } ${appContext.menuActive ? `menu-active` : ``} 
        ${
          appContext.scrollTop > this.headerCollapseHeight &&
          !appContext.menuActive
            ? `collapsed py-2`
            : `py-6`
        }`}
        >
          {appContext.device === `mobile` && (
            <div className="header__bg bg-sun-yellow fixed w-full top-0 left-0 right-0 z-20"></div>
          )}

          <nav className="relative grid z-20 bg-sun-yellow">
            <div className="animation-appear grid-end-9">
              <Link
                to="/"
                onClick={() => {
                  appContext.setMenuActive(false);
                }}
              >
                <h2 className="header__title f2">
                  <span className="font-bold">Fluff Casual Cosmetics™</span>
                </h2>

                {appContext &&
                  appContext.device !== `mobile` &&
                  appContext.scrollTop < this.headerCollapseHeight && (
                    <h3 className="header__headline f2 font-medium">
                      {appContext.navText}
                    </h3>
                  )}
              </Link>
            </div>

            <div
              className={`grid-end-3 flex justify-end ${
                appContext.scrollTop > this.headerCollapseHeight ||
                appContext.device === `mobile`
                  ? `items-center`
                  : `items-start`
              }`}
            >
              <button
                type="button"
                className="header__cart relative pointer-events-auto"
                onClick={this.toggleCart}
              >
                <CartIcon />
              </button>

              <button
                type="button"
                className={`header__menu gpu ml-2 pt-1 pointer-events-auto ${
                  appContext.menuActive ? `menu-active` : ``
                } relative`}
                onClick={this.toggleMenu}
              >
                <div className="header__menu__line header__menu__line--0 w-full mb-1 bg-black"></div>
                <div className="header__menu__line header__menu__line--1 w-full mb-1 bg-black"></div>
                <div className="header__menu__line header__menu__line--2 w-full mb-1 bg-black"></div>
              </button>
            </div>
          </nav>

          <div className="header__overlay w-screen h-screen absolute top-0 right-0 bottom-0 left-0 z-10 flex flex-col justify-between bg-sun-yellow">
            {appContext.menuActive && (
              <>
                <ul className="header__overlay__links px-6 xs:px-4 pt-16 relative f2 font-medium pt-24 sm:pt-16 pb-24">
                  <li
                    className="header__overlay__link relative xs:pt-1 animation-appear"
                    style={{ animationDelay: `150ms` }}
                  >
                    <Link
                      to="/products"
                      className="relative block py-6"
                      onClick={() => {
                        appContext.setMenuActive(false);
                      }}
                    >
                      <span className="block">Shop Casual Cosmetics™</span>
                    </Link>
                  </li>

                  <li
                    className="header__overlay__link relative animation-appear"
                    style={{ animationDelay: `250ms` }}
                  >
                    <Link
                      to="/issues"
                      className="relative block py-6"
                      onClick={() => {
                        appContext.setMenuActive(false);
                      }}
                    >
                      <span className="block">Read Issues</span>
                    </Link>
                  </li>

                  <li
                    className="header__overlay__link relative animation-appear"
                    style={{ animationDelay: `300ms` }}
                  >
                    <Link
                      to="/makeunder"
                      className="relative block py-6"
                      onClick={() => {
                        appContext.setMenuActive(false);
                      }}
                    >
                      <span className="block">Book a Makeunder®</span>
                    </Link>
                  </li>

                  <li
                    className="header__overlay__link relative animation-appear"
                    style={{ animationDelay: `250ms` }}
                  >
                    <Link
                      to="/about"
                      className="relative block py-6"
                      onClick={() => {
                        appContext.setMenuActive(false);
                      }}
                    >
                      <span className="block">About Us</span>
                    </Link>
                  </li>
                </ul>

                <div
                  className="animation-appear"
                  style={{ animationDelay: `300ms` }}
                >
                  <Footer menu />
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const Header = () => (
  <Location>
    {({ location }) => (
      <AppContext.Consumer>
        {appContext => (
          <HeaderComponent appContext={appContext} location={location} />
        )}
      </AppContext.Consumer>
    )}
  </Location>
);

export default Header;
