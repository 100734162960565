/* eslint-disable react/prop-types */

import React from "react";
import { Link } from "gatsby";

import { AppContext } from "~context/AppContext";

const Footer = props => (
  <AppContext.Consumer>
    {appContext => (
      <footer className="footer relative pb-16 xs:pb-8 bg-sun-yellow">
        <nav className="grid">
          {!props.menu && (
            <h3 className="footer__heading grid-end-12 pt-4 xs:pt-8 pb-12 xs:pb-8 f2 font-medium">
              The future of beauty is more than makeup.
            </h3>
          )}

          <div className="grid-end-3 xs:grid-end-12">
            <a
              href="https://www.instagram.com/itsall.fluff/"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Instagram
            </a>

            <a
              href="https://www.facebook.com/itsall.fluff/"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Facebook
            </a>

            <a
              href="https://itsall-fluff.tumblr.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Tumblr
            </a>

            <a
              href="https://twitter.com/itsall_fluff"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Twitter
            </a>
          </div>

          <div className="grid-end-3 xs:grid-end-12 xs:mt-4">
            <a
              href="https://www.youtube.com/channel/UCxzewAbiNDx9QOe0nJhEeCw"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              YouTube
            </a>

            <a
              href="https://issues.itsallfluff.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Issues
            </a>

            <Link
              to="/newsletter"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Newsletter
            </Link>

            <Link
              to="/makeunder"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Book a Makeunder
            </Link>
          </div>

          <div className="grid-end-3 xs:grid-end-12 xs:mt-4">
            <a
              href="https://www.dropbox.com/sh/c5ohzs81njr69wg/AAA3SVPpVdLt-eyOeK3eo4Wca?dl=0"
              target="_blank"
              rel="noopener noreferrer"
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Press Kit
            </a>

            <Link
              to="/faqs"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              FAQ&rsquo;s
            </Link>

            <Link
              to="/terms"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              T&amp;Cs + Privacy Policy
            </Link>

            <Link
              to="/shipping"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              Shipping and Returns
            </Link>
          </div>

          <div className="grid-end-3 xs:grid-end-12 grid-start-10 xs:grid-start-1 flex flex-col items-start justify-between xs:items-start justify-end xs:justify-start">
            <Link
              to="/contact"
              onClick={() => {
                appContext.setMenuActive(false);
              }}
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:pt-6 xs:py-1` : ``
              }`}
            >
              Contact
            </Link>

            <p
              className={`block b1 font-light py-1 ${
                !props.menu ? `xs:py-1` : ``
              }`}
            >
              $AUD
            </p>

            <a
              href="https://loveandmoney.agency/"
              target="_blank"
              rel="noopener noreferrer"
              className="block xs:pt-12 b1 font-light py-1"
            >
              Made with &lt;3 + $
            </a>
          </div>
        </nav>
      </footer>
    )}
  </AppContext.Consumer>
);

export default Footer;
