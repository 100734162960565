/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";

import { AppContext } from "~context/AppContext";

class BannerComponent extends Component {
  componentDidMount() {}

  //

  render() {
    return (
      <section
        className="banner w-full relative"
        style={{
          backgroundImage: `url(${this.props.bannerImage.src})`
        }}
      >
        <article
          className="banner__inner animation-appear w-3/4 top-0 left-0 absolute px-6 xs:px-3 pt-2 xs:py-8"
          style={{ animationDelay: `100ms` }}
        >
          <h1 className="banner__text w-full d1 font-medium text-sun-yellow">
            {this.props.bannerText}
          </h1>
        </article>

        {this.props.button && (
          <Link to={this.props.button.buttonLink}>
            <div className="w-full flex justify-center">
              <button
                type="button"
                className={`absolute bottom-0 mb-16 b-button font-medium p-4 xs:p-2 w-1/5 xs:w-1/2 ${this.props.button.buttonBg} hover:bg-black hover:text-sun-yellow ${this.props.button.buttonTextColor}`}
              >
                {this.props.button.buttonText}
              </button>
            </div>
          </Link>
        )}
      </section>
    );
  }
}

const Banner = props => {
  const { bannerImage, bannerText, button } = props;
  return (
    <AppContext.Consumer>
      {appContext => (
        <BannerComponent
          appContext={appContext}
          bannerImage={bannerImage}
          bannerText={bannerText}
          button={button}
        />
      )}
    </AppContext.Consumer>
  );
};

export default Banner;
