/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import DatePicker from "react-pikaday-datepicker";
import { fancyWarning, validateEmail } from "~utils/helpers";

// 10% off: NKiz8F
// makeunder: JGGgbS
// newsletter: Kqkz9T

class KlaviyoFormComponent extends Component {
  state = {
    form: {},
    submitted: false,
    submitting: false
  };

  ajaxUrl = `//manage.kmail-lists.com/ajax/subscriptions/subscribe`;

  requiredInputs = [`first_name`, `last_name`, `email`];

  //

  onDateChange = date => {
    const { form } = this.state;

    form.date = {
      interacted: true,
      value: date.toString(),
      valid: true
    };

    this.setState({
      form
    });
  };

  onInputChange = e => {
    const { form } = this.state;
    const { value } = e.target;
    const key = e.target.getAttribute(`name`);

    if (!form[key]) {
      form[key] = {};
    }

    form[key].value = value;
    form[key].interacted = value !== ``;

    switch (key) {
      case `email`:
        form.email.valid = validateEmail(value);

        break;

      case `first_name`:
      case `last_name`:
        form[key].valid = value !== ``;

        break;

      default:
        break;
    }

    this.setState({ form });
  };

  onSelectChange = e => {
    const { form } = this.state;
    const { value } = e.target;
    const key = e.target.getAttribute(`name`);

    if (!form[key]) {
      form[key] = {};
    }

    form[key].value = value;
    form[key].interacted = true;

    this.setState({ form });
  };

  //

  submit = e => {
    e.preventDefault();

    if (!this.validate()) {
      return false;
    }

    this.setState({
      submitting: true
    });

    const formData = new FormData(e.target);

    fetch(this.ajaxUrl, {
      body: formData,
      headers: {
        Accept: `application/json`,
        "Content-Type": `application/json`
      },
      method: `post`,
      mode: `no-cors`
    }).then(() => {
      setTimeout(() => {
        this.setState({
          submitted: true
        });
      }, 300);
    });

    return false;
  };

  validate = () => {
    let valid = true;

    const { form } = this.state;

    this.requiredInputs.forEach(requiredInput => {
      if (!form[requiredInput]) {
        form[requiredInput] = {
          interacted: true,
          valid: false,
          value: ``
        };

        valid = false;
      }
    });

    if (!valid) {
      this.setState({
        form
      });

      fancyWarning(`Required form values missing`);
    } else {
      Object.keys(this.state.form).forEach(inputKey => {
        if (!valid) {
          return;
        }

        if (this.state.form[inputKey].valid === false) {
          fancyWarning(`Invalid form value: ${inputKey}`);

          valid = false;
        }
      });
    }

    return valid;
  };

  //

  render() {
    const { children, className, footer, klaviyoId } = this.props;
    const { date } = this.state;

    let formSubmitMessage = `Thanks! Check your inbox.`;

    if (this.props.makeunder) {
      formSubmitMessage = `Y: info received. We’ll be in touch to book your spot and details.
      Check your inbox.`;
    } else if (this.props.cult) {
      formSubmitMessage = `Welcome. Your code for 10% off is: ACTPRETTY`;
    }

    return (
      <>
        {(this.state.submitted && (
          <section
            className={`form ${className || ``} animation-appear relative grid`}
          >
            <h3 className="grid-end-8 xs:grid-end-12 f2">
              {formSubmitMessage}
            </h3>
          </section>
        )) || (
          <form
            className={`form ${className ||
              ``} relative grid f3 font-medium f3 pt-16 xs:pt-4 ${this.state
              .submitting && `submitting`}`}
            onSubmit={this.submit}
          >
            {children}
            <input type="hidden" name="g" value={klaviyoId} />

            {this.props.makeunder && (
              <input type="hidden" name="$fields" value="phone,date,time" />
            )}

            <input
              id="k_id_first_name"
              name="first_name"
              className={`form__input animation-appear grid-end-4 ${
                footer ? `grid-start-5` : `grid-start-1`
              } xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1 font-medium f3`}
              style={{ animationDelay: `100ms` }}
              onChange={this.onInputChange}
              placeholder="First Name*"
              required
              type="text"
            />

            <input
              id="k_id_last_name"
              name="last_name"
              className="form__input animation-appear grid-end-4 xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1 font-medium f3"
              style={{ animationDelay: `100ms` }}
              onChange={this.onInputChange}
              placeholder="Last Name*"
              required
              type="text"
            />

            <input
              id="k_id_email"
              name="email"
              className={`form__input animation-appear font-medium f3 ${
                this.props.makeunder ? `grid-end-4` : `grid-end-8`
              } ${
                footer ? `grid-start-5` : `grid-start-1`
              } xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1`}
              style={{ animationDelay: `200ms` }}
              onChange={this.onInputChange}
              placeholder="Email*"
              required
              type="email"
            />

            {this.props.makeunder && (
              <input
                id="k_id_phone"
                name="phone"
                className="form__input animation-appear grid-end-4 xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1 font-medium f3"
                style={{ animationDelay: `200ms` }}
                onChange={this.onInputChange}
                placeholder="Phone*"
                type="text"
              />
            )}

            {this.props.makeunder && (
              <DatePicker
                id="k_id_date"
                name="date"
                className="form__input animation-appear grid-end-4 xs:grid-end-12 xs:grid-start-1 grid-start-1 relative block mt-2 pl-1 cursor-pointer font-medium f3"
                style={{ animationDelay: `300ms` }}
                disableDayFn={datePickerDate => {
                  const parsedDate = datePickerDate.toISOString().split(`T`)[0];
                  //  TODO : hacking to day before, must be a timezone thing
                  const disabledDays = [
                    `2019-12-27`,
                    `2020-01-03`,
                    `2020-01-10`
                  ];

                  return (
                    datePickerDate.getDay() === 1 ||
                    datePickerDate.getDay() === 2 ||
                    datePickerDate.getDay() === 3 ||
                    datePickerDate.getDay() === 4 ||
                    datePickerDate.getDay() === 5 ||
                    datePickerDate.getDay() === 0 ||
                    disabledDays.includes(parsedDate)
                  );
                }}
                format="DD/MM/YYYY"
                onChange={this.onDateChange}
                placeholder="Date ➺"
                value={date || ``}
              />
            )}

            {this.props.makeunder && (
              <select
                id="k_id_time"
                name="time"
                className="animation-appear grid-end-4 xs:grid-end-12 xs:grid-start-1 relative block mt-2 pl-1 cursor-pointer font-medium f3"
                style={{ animationDelay: `300ms` }}
                onChange={this.onSelectChange}
                defaultValue="Time ➺"
              >
                <option value="Time ➺" disabled selected>
                  Time ➺
                </option>
                <option value="11:00am">11:00 am</option>
                <option value="11:30am">11:30 am</option>
                <option value="12:00pm">12:00 pm</option>
                <option value="12:30pm">12:30 pm</option>
                <option value="1:00pm">1:00 pm</option>
                <option value="1:30pm">1:30 pm</option>
                <option value="2:00pm">2:00 pm</option>
                <option value="2:30pm">2:30 pm</option>
                <option value="3:00pm">3:00 pm</option>
                <option value="3:30pm">3:30 pm</option>
              </select>
            )}

            <div
              className={`animation-appear grid-end-8 ${
                footer ? `grid-start-5` : `grid-start-1`
              } xs:grid-end-10 xs:grid-start-2 flex items-start justify-end mt-4`}
              style={{ animationDelay: `400ms` }}
            >
              <input
                className="form__submit py-2 cursor-pointer b2 font-medium f3"
                type="submit"
                value={`${this.state.submitting ? `SUBMITTING` : `SUBMIT`}`}
              />
            </div>
          </form>
        )}
      </>
    );
  }
}

const KlaviyoForm = props => {
  const { children, className, cult, footer, klaviyoId, makeunder } = props;

  return (
    <KlaviyoFormComponent
      className={className}
      cult={cult}
      footer={footer}
      klaviyoId={klaviyoId}
      makeunder={makeunder}
    >
      {children}
    </KlaviyoFormComponent>
  );
};

export default KlaviyoForm;
