import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext";
import Header from "~components/Header";
import Footer from "~components/Footer";

export default function Layout({ children, className }) {
  return (
    <AppContext.Consumer>
      {appContext => (
        <>
          <Header />

          <main
            className={`layout ${className} ${
              appContext.cartActive ? `cart-active` : ``
            }`}
          >
            {children}

            <Footer />
          </main>
        </>
      )}
    </AppContext.Consumer>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};
